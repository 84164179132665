import React, { useEffect, useState } from "react";
import "./App.css";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import moment from "moment";

import it from "date-fns/locale/it";

import "react-datepicker/dist/react-datepicker.css";

import { Formik } from "formik";

import langFile from "./lang.json";

registerLocale("it", it);

// import countries  from "i18n-iso-countries";

function App() {
  const [stepNumber, setStepNumber] = useState(1);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageCF, setErrorMessageCF] = useState("");

  const sendPostMessage = () => {
    let height = 0;
    if (height !== document.getElementById("root").offsetHeight) {
      height = document.getElementById("root").offsetHeight;
      window.parent.postMessage(
        {
          frameId: "registrationForm",
          frameHeight: height,
        },
        "*"
      );
    }
  };

  useEffect(() => {
    window.onload = () => sendPostMessage();
    window.onresize = () => sendPostMessage();
  }, []);

  // countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  // countries.registerLocale(require("i18n-iso-countries/langs/it.json"));

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const lang = getParameterByName("l");

  if (!lang || (lang !== "it" && lang !== "en")) return null;
  if (!getParameterByName("e")) return null;
  if (!getParameterByName("f")) return null;

  // const nazioni = countries.getNames(lang);

  const getString = (item) => {
    if (langFile && langFile[lang] && langFile[lang][item]) {
      return langFile[lang][item];
    }
    return "";
  };

  const eventId = getParameterByName("e");
  const formId = getParameterByName("f");
  const selectedComitato = getParameterByName("c");
  const formType = getParameterByName("t");

  const specialComitato =
    selectedComitato === "CFA" ||
    selectedComitato === "CFAv1" ||
    selectedComitato === "TFNv1" ||
    selectedComitato === "TFN" ||
    selectedComitato === "TFNv1" ||
    selectedComitato === "SostPF" ||
    selectedComitato === "Covisoc";

  const defaultFormFields = {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      luogoNascita: "",
      CF: "",
      address: "",
      cap: "",
      province: "",
      address2: "",
      cap2: "",
      province2: "",
      tel1: "",
      tel2: "",
      email: "",
      pec: "",
      comitato: selectedComitato,
      organo: selectedComitato === "AOSTA" ? "Giudice-Sportivo" : "",
      organo1: false,
      organo2: selectedComitato === "AOSTA",
      delegazione: "",
      requisiti: "",
      competenza: "",
      incarichiAttuali: "",
      qualeIncarico: "",
      ostative1: "",
      ostative2: "",
      condanne: "",
      misure: "",
      condizioni: "",
      condizioniInfo: "",
      rapportoPA: "",
      altreDichiarazioni1: false,
      altreDichiarazioni2: false,
      altreDichiarazioni3: false,
      altreDichiarazioni4: false,
      altreDichiarazioni5: false,
      altreDichiarazioni6: false,
      cvAttachment: "",
      privacypolicy: false,
    },
    defaultErrorsFormFields = {
      firstName: getString("required"),
      lastName: getString("required"),
      dateOfBirth: getString("required"),
      luogoNascita: getString("required"),
      CF: getString("required"),
      address: getString("required"),
      province: getString("required"),
      tel1: getString("required"),
      tel2: "",
      email: getString("required"),
      email2: getString("required"),
      pec: "",
      comitato: getString("required"),
      organo: getString("required"),
      delegazione: getString("required"),
      requisiti: getString("required"),
      competenza: getString("required"),
      incarichiAttuali: getString("required"),
      qualeIncarico: "",
      ostative1: getString("required"),
      ostative2: getString("required"),
      condanne: getString("required"),
      misure: getString("required"),
      condizioni: getString("required"),
      condizioniInfo: getString("required"),
      rapportoPA: getString("required"),
      altreDichiarazioni1: getString("required"),
      altreDichiarazioni2: getString("required"),
      altreDichiarazioni3: getString("required"),
      altreDichiarazioni4: getString("required"),
      altreDichiarazioni5: getString("required"),
      altreDichiarazioni6: getString("required"),
      cvAttachment: getString("required"),
      privacypolicy: getString("required"),
    },
    selectLists = {
      en: {},
      it: {
        comitati: {
          ABRUZZO: [
            { label: "DELEGAZIONE PROVINCIALE DELL'AQUILA", key: "AQUILA" },
            { label: "DELEGAZIONE PROVINCIALE DI CHIETI", key: "CHIETI" },
            { label: "DELEGAZIONE PROVINCIALE DI PESCARA", key: "PESCARA" },
            { label: "DELEGAZIONE PROVINCIALE DI TERAMO", key: "TERAMO" },
            { label: "DELEGAZIONE DISTRETTUALE DI AVEZZANO", key: "AVEZZANO" },
            { label: "DELEGAZIONE DISTRETTUALE DI VASTO", key: "VASTO" },
          ],
          BASILICATA: [
            { label: "DELEGAZIONE PROVINCIALE DI MATERA", key: "MATERA" },
            { label: "DELEGAZIONE PROVINCIALE DI POTENZA", key: "POTENZA" },
          ],
          CALABRIA: [
            { label: "DELEGAZIONE PROVINCIALE DI CATANZARO", key: "CATANZARO" },
            { label: "DELEGAZIONE PROVINCIALE DI COSENZA", key: "COSENZA" },
            { label: "DELEGAZIONE PROVINCIALE DI CROTONE", key: "CROTONE" },
            { label: "DELEGAZIONE DISTRETTUALE DI LOCRI", key: "LOCRI" },
            {
              label: "DELEGAZIONE PROVINCIALE DI REGGIO CALABRIA",
              key: "REGGIO-CALABRIA",
            },
            { label: "DELEGAZIONE DISTRETTUALE DI ROSSANO", key: "ROSSANO" },
            {
              label: "DELEGAZIONE PROVINCIALE DI VIBO VALENTIA",
              key: "VIBO-VALENTIA",
            },
            {
              label: "DELEGAZIONE DISTRETTUALE DI GIOIA TAURO",
              key: "GIOIA-TAURO",
            },
          ],
          CAMPANIA: [
            { label: "DELEGAZIONE PROVINCIALE DI CASERTA", key: "CASERTA" },
            { label: "DELEGAZIONE PROVINCIALE DI NAPOLI", key: "NAPOLI" },
          ],
          EMILIAROMAGNA: [
            { label: "DELEGAZIONE PROVINCIALE DI BOLOGNA", key: "BOLOGNA" },
            { label: "DELEGAZIONE PROVINCIALE DI FERRARA", key: "FERRARA" },
            {
              label: "DELEGAZIONE PROVINCIALE DI FORLI' CESENA",
              key: "FORLI-CESENA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI MODENA", key: "MODENA" },
            { label: "DELEGAZIONE PROVINCIALE DI PARMA", key: "PARMA" },
            { label: "DELEGAZIONE PROVINCIALE DI PIACENZA", key: "PIACENZA" },
            { label: "DELEGAZIONE PROVINCIALE DI RAVENNA", key: "RAVENNA" },
            {
              label: "DELEGAZIONE PROVINCIALE DI REGGIO EMILIA",
              key: "REGGIO-EMILIA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI RIMINI", key: "RIMINI" },
          ],
          FRIULIVENEZIAGIULIA: [
            { label: "DELEGAZIONE PROVINCIALE DI GORIZIA", key: "GORIZIA" },
            { label: "DELEGAZIONE PROVINCIALE DI PORDENONE", key: "PORDENONE" },
            { label: "DELEGAZIONE DISTRETTUALE DI TOLMEZZO", key: "TOLMEZZO" },
            { label: "DELEGAZIONE PROVINCIALE DI TRIESTE", key: "TRIESTE" },
            { label: "DELEGAZIONE PROVINCIALE DI UDINE", key: "UDINE" },
          ],
          LAZIO: [
            { label: "DELEGAZIONE PROVINCIALE DI FROSINONE", key: "FROSINONE" },
            { label: "DELEGAZIONE PROVINCIALE DI RIETI", key: "RIETI" },
            { label: "DELEGAZIONE PROVINCIALE DI ROMA", key: "ROMA" },
            { label: "DELEGAZIONE PROVINCIALE DI LATINA", key: "LATINA" },
            { label: "DELEGAZIONE PROVINCIALE DI VITERBO", key: "VITERBO" },
          ],
          LIGURIA: [
            { label: "DELEGAZIONE DISTRETTUALE DI CHIAVARI", key: "CHIAVARI" },
            { label: "DELEGAZIONE PROVINCIALE DI GENOVA", key: "GENOVA" },
            { label: "DELEGAZIONE PROVINCIALE DI IMPERIA", key: "IMPERIA" },
            { label: "DELEGAZIONE PROVINCIALE DI LA SPEZIA", key: "LA-SPEZIA" },
            { label: "DELEGAZIONE PROVINCIALE DI SAVONA", key: "SAVONA" },
          ],
          LOMBARDIA: [
            { label: "DELEGAZIONE PROVINCIALE DI BERGAMO", key: "BERGAMO" },
            { label: "DELEGAZIONE PROVINCIALE DI BRESCIA", key: "BRESCIA" },
            { label: "DELEGAZIONE PROVINCIALE DI COMO", key: "COMO" },
            { label: "DELEGAZIONE PROVINCIALE DI CREMONA", key: "CREMONA" },
            { label: "DELEGAZIONE PROVINCIALE DI LECCO", key: "LECCO" },
            { label: "DELEGAZIONE PROVINCIALE DI LEGNANO", key: "LEGNANO" },
            { label: "DELEGAZIONE PROVINCIALE DI LODI", key: "LODI" },
            { label: "DELEGAZIONE PROVINCIALE DI MANTOVA", key: "MANTOVA" },
            { label: "DELEGAZIONE PROVINCIALE DI MILANO", key: "MILANO" },
            { label: "DELEGAZIONE PROVINCIALE DI MONZA", key: "MONZA" },
            { label: "DELEGAZIONE PROVINCIALE DI PAVIA", key: "PAVIA" },
            { label: "DELEGAZIONE PROVINCIALE DI SONDRIO", key: "SONDRIO" },
            { label: "DELEGAZIONE PROVINCIALE DI VARESE", key: "VARESE" },
          ],
          MARCHE: [
            {
              label: "DELEGAZIONE PROVINCIALE DI PESARO URBINO",
              key: "PESARO-URBINO",
            },
            {
              label: "DELEGAZIONE PROVINCIALE DI ASCOLI PICENO",
              key: "ASCOLI-PICENO",
            },
            { label: "DELEGAZIONE PROVINCIALE DI ANCONA", key: "ANCONA" },
            { label: "DELEGAZIONE PROVINCIALE DI MACERATA", key: "MACERATA" },
            { label: "DELEGAZIONE PROVINCIALE DI FERMO", key: "FERMO" },
          ],
          MOLISE: [
            {
              label: "DELEGAZIONE PROVINCIALE DI CAMPOBASSO",
              key: "CAMPOBASSO",
            },
            { label: "DELEGAZIONE PROVINCIALE DI ISERNIA", key: "ISERNIA" },
          ],
          AOSTA: [{ label: "DELEGAZIONE PROVINCIALE DI AOSTA", key: "AOSTA" }],
          PIEMONTEAOSTA: [
            {
              label: "DELEGAZIONE PROVINCIALE DI ALESSANDRIA",
              key: "ALESSANDRIA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI ASTI", key: "ASTI" },
            { label: "DELEGAZIONE PROVINCIALE DI BIELLA", key: "BIELLA" },
            { label: "DELEGAZIONE PROVINCIALE DI CUNEO", key: "CUNEO" },
            { label: "DELEGAZIONE DISTRETTUALE DI IVREA", key: "IVREA" },
            { label: "DELEGAZIONE PROVINCIALE DI NOVARA", key: "NOVARA" },
            { label: "DELEGAZIONE DISTRETTUALE DI PINEROLO", key: "PINEROLO" },
            { label: "DELEGAZIONE PROVINCIALE DI TORINO", key: "TORINO" },
            {
              label: "DELEGAZIONE PROVINCIALE DI VERBANO CUSIO OSSOLA",
              key: "VERBANO-CUSIO-OSSOLA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI VERCELLI", key: "VERCELLI" },
            { label: "DELEGAZIONE PROVINCIALE DI AOSTA", key: "AOSTA" },
          ],
          PUGLIA: [
            { label: "DELEGAZIONE PROVINCIALE DI BARI", key: "BARI" },
            { label: "DELEGAZIONE PROVINCIALE DI BRINDISI", key: "BRINDISI" },
            { label: "DELEGAZIONE PROVINCIALE DI FOGGIA", key: "FOGGIA" },
            { label: "DELEGAZIONE PROVINCIALE DI LECCE", key: "LECCE" },
            { label: "DELEGAZIONE PROVINCIALE DI TARANTO", key: "TARANTO" },
            { label: "DELEGAZIONE DISTRETTUALE DI MAGLIE", key: "MAGLIE" },
            { label: "DELEGAZIONE DISTRETTUALE DI BAT", key: "BAT" },
          ],
          SARDEGNA: [
            { label: "DELEGAZIONE PROVINCIALE DI CAGLIARI", key: "CAGLIARI" },
            { label: "DELEGAZIONE PROVINCIALE DI CARBONIA", key: "CARBONIA" },
            { label: "DELEGAZIONE PROVINCIALE DI ORISTANO", key: "ORISTANO" },
            {
              label: "DELEGAZIONE PROVINCIALE DI OLBIA TEMPIO",
              key: "OLBIA-TEMPIO",
            },
            { label: "DELEGAZIONE PROVINCIALE DI NUORO", key: "NUORO" },
            { label: "DELEGAZIONE PROVINCIALE DI SASSARI", key: "SASSARI" },
            { label: "DELEGAZIONE PROVINCIALE DI OGLIASTRA", key: "OGLIASTRA" },
          ],
          SICILIA: [
            { label: "DELEGAZIONE PROVINCIALE DI AGRIGENTO", key: "AGRIGENTO" },
            {
              label: "DELEGAZIONE PROVINCIALE DI BARCELLONA POZZO DI GOTTO",
              key: "BARCELLONA-POZZO-DI-GOTTO",
            },
            {
              label: "DELEGAZIONE PROVINCIALE DI CALTANISSETTA",
              key: "CALTANISSETTA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI CATANIA", key: "CATANIA" },
            { label: "DELEGAZIONE PROVINCIALE DI ENNA", key: "ENNA" },
            { label: "DELEGAZIONE PROVINCIALE DI MESSINA", key: "MESSINA" },
            { label: "DELEGAZIONE PROVINCIALE DI PALERMO", key: "PALERMO" },
            { label: "DELEGAZIONE PROVINCIALE DI RAGUSA", key: "RAGUSA" },
            { label: "DELEGAZIONE PROVINCIALE DI SIRACUSA", key: "SIRACUSA" },
            { label: "DELEGAZIONE PROVINCIALE DI TRAPANI", key: "TRAPANI" },
          ],
          TOSCANA: [
            { label: "DELEGAZIONE PROVINCIALE DI AREZZO", key: "AREZZO" },
            { label: "DELEGAZIONE PROVINCIALE DI FIRENZE", key: "FIRENZE" },
            { label: "DELEGAZIONE PROVINCIALE DI GROSSETO", key: "GROSSETO" },
            { label: "DELEGAZIONE PROVINCIALE DI LIVORNO", key: "LIVORNO" },
            { label: "DELEGAZIONE PROVINCIALE DI LUCCA", key: "LUCCA" },
            {
              label: "DELEGAZIONE PROVINCIALE DI MASSA CARRARA",
              key: "MASSA-CARRARA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI PISA", key: "PISA" },
            { label: "DELEGAZIONE PROVINCIALE DI PISTOIA", key: "PISTOIA" },
            { label: "DELEGAZIONE PROVINCIALE DI PRATO", key: "PRATO" },
            { label: "DELEGAZIONE PROVINCIALE DI SIENA", key: "SIENA" },
          ],
          UMBRIA: [
            { label: "DELEGAZIONE PROVINCIALE DI PERUGIA", key: "PERUGIA" },
            { label: "DELEGAZIONE PROVINCIALE DI TERNI", key: "TERNI" },
            {
              label: "DELEGAZIONE DISTRETTUALE DI CITTA’ DI CASTELLO",
              key: "CITTA-DI-CASTELLO",
            },
            { label: "DELEGAZIONE DISTRETTUALE DI FOLIGNO", key: "FOLIGNO" },
            { label: "DELEGAZIONE DISTRETTUALE DI GUBBIO", key: "GUBBIO" },
            { label: "DELEGAZIONE DISTRETTUALE DI ORVIETO", key: "ORVIETO" },
          ],
          VENETO: [
            { label: "DELEGAZIONE PROVINCIALE DI BELLUNO", key: "BELLUNO" },
            {
              label: "DELEGAZIONE DISTRETTUALE DI BASSANO DEI GRAPPA",
              key: "BASSANO-DEI-GRAPPA",
            },
            { label: "DELEGAZIONE PROVINCIALE DI PADOVA", key: "PADOVA" },
            { label: "DELEGAZIONE PROVINCIALE DI ROVIGO", key: "ROVIGO" },
            {
              label: "DELEGAZIONE DISTRETTUALE DI S. DONA’ DI PIAVE",
              key: "S-DONA-DI-PIAVE",
            },
            { label: "DELEGAZIONE PROVINCIALE DI TREVISO", key: "TREVISO" },
            { label: "DELEGAZIONE PROVINCIALE DI VENEZIA", key: "VENEZIA" },
            { label: "DELEGAZIONE PROVINCIALE DI VERONA", key: "VERONA" },
            { label: "DELEGAZIONE PROVINCIALE DI VICENZA", key: "VICENZA" },
          ],
          BOLZANO: [],
          TRENTO: [],
          CFA: [],
          TFN: [],
          CFAv1: [],
          TFNv1: [],
          SostPF: [],
          Covisoc: [],
        },
        organi: [
          {
            key: "CSA/TFT",
            label:
              " Corte Sportiva d’Appello/Tribunale Federale a livello territoriale",
          },
          { key: "Giudice-Sportivo", label: " Giudice Sportivo territoriale" },
        ],
        requisitiNomina: [
          {
            key: "Laurea-in-Giurisprudenza",
            label: "Laurea in Giurisprudenza",
          },
          {
            key: "Diplomato-tesserato-almeno-3-anni",
            label:
              "Diplomati delle scuole superiori che siano stati tesserati per la FIGC per almeno 3 anni",
          },
          {
            key: "Diplomato-esperienza-almeno-5-anni",
            label:
              "Diplomati delle scuole superiori che abbiano maturato almeno cinque anni di  esperienza nell’ordinamento sportivo",
          },
        ],
        requisitiNominaSostPF: [
          {
            key: "Professore-ruolo",
            label: "Professore universitario di ruolo in materie giuridiche",
          },
          { key: "Magistrato", label: "Magistrato" },
          {
            key: "Avvocato-stato-notaio",
            label: "Avvocato dello Stato/Notaio",
          },
          {
            key: "Avvocato-almeno-5-anni",
            label:
              "Avvocato iscritto da almeno cinque anni negli albi del relativo consiglio dell'ordine",
          },
          {
            key: "Dottori-commercialisti-contabili",
            label:
              "Iscritto all'albo dei dottori commercialisti e degli esperti contabili",
          },
          {
            key: "Ufficiale-forze-dell-ordine",
            label: "Ufficiale delle forze dell'ordine",
          },
          {
            key: "Laureato-materie-giuridiche",
            label:
              "Laureato in materie giuridiche, con due anni di esperienza nell'ordinamento sportivo",
          },
        ],
        requisitiNominaCFA: [
          {
            key: "Professore-ruolo",
            label: "Professore universitario di ruolo in materie giuridiche",
          },
          { key: "Magistrato", label: "Magistrato" },
          {
            key: "Avvocato-stato-notaio",
            label: "Avvocato dello Stato/Notaio",
          },
          {
            key: "Avvocato-almeno-5-anni",
            label:
              "Avvocato iscritto da almeno cinque anni negli albi del relativo consiglio dell'ordine",
          },
        ],
        requisitiNominaCovisoc: [
          {
            key: "Docenti-Universitari",
            label:
              "Docenti universitari di ruolo in materie giuridiche ed economico – aziendali, anche a riposo",
          },
          {
            key: "Magistrati",
            label: "Magistrati di qualsiasi giurisdizione, anche a riposo",
          },
          {
            key: "Dottori-Commercialisti-Avvocati",
            label:
              "Dottori commercialisti, avvocati, notai, avvocati dello Stato o consulenti del lavoro laureati in economia e commercio con almeno dieci anni di anzianità nella funzione, anche a riposo",
          },
        ],
        condanne: [
          {
            key: "Non-condannato",
            label:
              "3.a) che non è stato condannato, ancorché con sentenza penale non definitiva, per i delitti indicati nell’art. 22bis, comma 1 delle NOIF;",
          },
          {
            key: "Condannato",
            label:
              "3.b) che è stato condannato, ancorché con sentenza penale non definitiva, per i delitti indicati nell’art. 22bis, comma 1 delle NOIF;",
          },
        ],
        condanne1: [
          {
            key: "Non-condannato",
            label:
              "2.a) che non è stato condannato, ancorché con sentenza penale non definitiva, per i delitti indicati nell’art. 22bis, comma 1 delle NOIF;",
          },
          {
            key: "Condannato",
            label:
              "2.b) che è stato condannato, ancorché con sentenza penale non definitiva, per i delitti indicati nell’art. 22bis, comma 1 delle NOIF;",
          },
        ],
        misure: [
          {
            key: "Non-sottoposto",
            label:
              "4.a) che non è sottoposto e/o che non è stato sottoposto a misure di prevenzione o di sicurezza personale;",
          },
          {
            key: "Sottoposto",
            label:
              "4.b) Che è sottoposto a misure di prevenzione o di sicurezza personale",
          },
          {
            key: "Stato-sottoposto",
            label:
              "4.b) Che è stato sottoposto a misure di prevenzione o di sicurezza personale",
          },
        ],
        misure1: [
          {
            key: "Non-sottoposto",
            label:
              "3.a) che non è sottoposto e/o che non è stato sottoposto a misure di prevenzione o di sicurezza personale;",
          },
          {
            key: "Sottoposto",
            label:
              "3.b) Che è sottoposto a misure di prevenzione o di sicurezza personale",
          },
          {
            key: "Stato-sottoposto",
            label:
              "3.b) Che è stato sottoposto a misure di prevenzione o di sicurezza personale",
          },
        ],
        condizioni: [
          {
            key: "No-art-51",
            label:
              "5.a) che non si trova in una delle condizioni di cui all’art. 51, punti 2, 3 e 5 prima parte, del codice di procedura civile, rispetto a tesserati federali, a soci di società affiliate alla FIGC o a non soci cui è riconducibile, direttamente o indirettamente il controllo di società affiliate alla FIGC;",
          },
          {
            key: "Si-art-51",
            label:
              "5.b) che si trova, in una delle condizioni di cui all’art. 51, punti 2, 3 e 5 prima parte del codice di procedura civile, rispetto a tesserati federali, a soci di società affiliate alla FIGC o a non soci cui è riconducibile, direttamente o indirettamente il controllo di società affiliate alla FIGC",
          },
        ],
        condizioni1: [
          {
            key: "No-art-51",
            label:
              "4.a) che non si trova in una delle condizioni di cui all’art. 51, punti 2, 3 e 5 prima parte, del codice di procedura civile, rispetto a tesserati federali, a soci di società affiliate alla FIGC o a non soci cui è riconducibile, direttamente o indirettamente il controllo di società affiliate alla FIGC;",
          },
          {
            key: "Si-art-51",
            label:
              "4.b) che si trova, in una delle condizioni di cui all’art. 51, punti 2, 3 e 5 prima parte del codice di procedura civile, rispetto a tesserati federali, a soci di società affiliate alla FIGC o a non soci cui è riconducibile, direttamente o indirettamente il controllo di società affiliate alla FIGC",
          },
        ],
        pa: [
          {
            key: "No-dipendente",
            label:
              "6.a) ai sensi di quanto previsto dall’art. 53 del Dlgs n. 165/2001, di non essere dipendente di alcuna pubblica amministrazione;",
          },
          {
            key: "Dipendente",
            label:
              "6.b) ai sensi di quanto previsto dall’art. 53 del Dlgs n. 165/2001, di essere dipendente di pubblica amministrazione e di obbligarsi a depositare entro 30 giorni dalla eventuale nomina, l’autorizzazione a svolgere l’incarico rilasciata dalla amministrazione competente.",
          },
        ],
        pa1: [
          {
            key: "No-dipendente",
            label:
              "5.a) ai sensi di quanto previsto dall’art. 53 del Dlgs n. 165/2001, di non essere dipendente di alcuna pubblica amministrazione;",
          },
          {
            key: "Dipendente",
            label:
              "5.b) ai sensi di quanto previsto dall’art. 53 del Dlgs n. 165/2001, di essere dipendente di pubblica amministrazione e di obbligarsi a depositare entro 30 giorni dalla eventuale nomina, l’autorizzazione a svolgere l’incarico rilasciata dalla amministrazione competente.",
          },
        ],
      },
    };

  if (typeof selectLists["it"]["comitati"][selectedComitato] === "undefined") {
    return null;
  }

  const fromKeyToLabel = (source, keys) => {
    let labels = [];

    if (keys && typeof keys === "object" && keys.length > 0) {
      keys.forEach((key) => {
        for (let item of source) {
          if (item.key === key) {
            labels.push(item.label);
            break;
          }
        }
      });
    } else if (keys && typeof keys === "string") {
      for (let item of source) {
        if (item.key === keys) {
          return item.label;
        }
      }
    }

    return labels;
  };

  const uploadAvatar = (values) => {
    let data = new FormData();
    data.append("fileToUpload", values.cvAttachment);
    data.append(
      "customFileName",
      `${values.lastName.replace(/[^\w\s]/gi, "")}_CV_${moment().unix()}`
    );
    data.append("eventId", eventId);
    return fetch("https://core.penguinpass.it/upload/attachment/public", {
      method: "post",
      headers: new Headers({
        Accept: "application/json",
      }),
      body: data,
    });
  };

  const submitDo = async (params) => {
    console.log(params);
    const eventId = getParameterByName("e");

    uploadAvatar(params).then(function (res, data) {
      if (res && res.status && res.status === 200) {
        res.json().then(function (data) {
          if (data.status && data.status === "SUCCESS" && data.filepath) {
            let tmpParams = Object.assign({}, params);
            tmpParams.cvAttachment = data.filepath;
            tmpParams.dateOfBirth = moment(params.dateOfBirth).format(
              "DD/MM/YYYY"
            );

            fetch("https://figc.penguinpass.it/api", {
              // fetch('http://127.0.0.1:8888/pp/penguin_payment_php/api/success.php', {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                customer: tmpParams,
                eventId,
                formId,
              }),
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (res) {
                console.log(res);
                if (res.status === "OK") {
                  if (res.status === "ERROR") {
                    if (res.message === "Unique fields provided.") {
                      setErrorMessageCF("Codice fiscale già in uso.");
                      setStepNumber(1);
                    }
                  } else {
                    setStepNumber(4);
                    sendPostMessage();
                  }
                } else {
                  if (res.message) {
                    setErrorMessage(res.message);
                  }
                  setStepNumber(1);
                }
              })
              .catch(function (err) {
                console.log("Fetch Error :-S", err);
              });
          } else {
            setStepNumber(1);
            setErrorMessage("Failed to upload the CV, please try again.");
          }
        });
      }
    });
  };

  return (
    <div className="container">
      <div className="row pt-3">
        <div className="col-md-8 offset-md-2 order-md-1">
          <Formik
            initialValues={defaultFormFields}
            initialErrors={defaultErrorsFormFields}
            validate={(values) => {
              const errors = {};
              for (let value in values) {
                if (!values[value]) {
                  if (
                    value === "email" ||
                    value === "email2" ||
                    value === "CF" ||
                    value === "pec" ||
                    value === "tel2" ||
                    value === "competenza" ||
                    value === "condizioniInfo" ||
                    value === "qualeIncarico" ||
                    value === "address2" ||
                    value === "cap2" ||
                    value === "province2" ||
                    value === "delegazione" ||
                    value === "organo1" ||
                    value === "organo2" ||
                    value === "incarichiAttuali"
                  ) {
                    continue;
                  }
                  if (
                    specialComitato &&
                    selectedComitato !== "Covisoc" &&
                    (value === "organo" || value === "requisiti")
                  ) {
                    continue;
                  }
                  if (selectedComitato === "Covisoc" && value === "organo") {
                    continue;
                  }
                  if (
                    formType === "Safe" &&
                    (value === "ostative2" ||
                      value === "requisiti" ||
                      value === "altreDichiarazioni6")
                  ) {
                    continue;
                  }
                  errors[value] = getString("required");
                }
              }

              if (!values.email) {
                errors.email = getString("required");
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = getString("invalid_email");
              }

              if (!values.email2) {
                errors.email2 = getString("required");
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email2)
              ) {
                errors.email2 = getString("invalid_email");
              }

              if (!values.CF) {
                errors.CF = getString("required");
              } else if (
                !/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i.test(values.CF)
              ) {
                errors.CF = getString("invalid_cf");
              }

              if (
                values.email &&
                values.email2 &&
                values.email !== values.email2
              ) {
                errors.email2 = getString("email_no_match");
              }

              if (values.incarichiAttuali === "si" && !values.qualeIncarico) {
                errors.qualeIncarico = getString("required");
              }

              if (values.condizioni === "Si-art-51" && !values.condizioniInfo) {
                errors.condizioniInfo = getString("required");
              }

              if (
                values.cvAttachment &&
                values.cvAttachment.type.indexOf("pdf") === -1
              ) {
                errors.cvAttachment = getString("acceptedFormat");
              }

              if (values.cvAttachment && values.cvAttachment.size >= 2000000) {
                errors.cvAttachment = getString("maxFileSize");
              }

              if (
                values.cvAttachment.name.split(".")[
                  values.cvAttachment.name.split(".").length - 1
                ] !== "pdf"
              ) {
                errors.cvAttachment = getString("uppercasePDF");
              }

              if (
                selectedComitato !== "CFA" &&
                selectedComitato !== "TFN" &&
                selectedComitato !== "Covisoc" &&
                (values.organo === "Giudice-Sportivo" ||
                  values.organo === "CSA/TFT-e-Giudice-Sportivo") &&
                !values.delegazione
              ) {
                errors.delegazione = getString("required");
              }
              console.log(errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setStepNumber(3);
              submitDo(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
            }) => (
              <form
                className="needs-validation"
                noValidate
                id="checkout-form"
                onSubmit={handleSubmit}
              >
                <div
                  id="personal-details"
                  style={{ display: stepNumber === 1 ? "block" : "none" }}
                >
                  <h4 className="mb-3">{getString("title1")}</h4>
                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="firstName">
                        {getString("firstName")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      <div className="invalid-feedback">
                        {errors.firstName && isConfirmed && errors.firstName}
                      </div>
                    </div>

                    <div className="col-md-6 mb-1">
                      <label htmlFor="lastName">{getString("lastName")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      <div className="invalid-feedback">
                        {errors.lastName && isConfirmed && errors.lastName}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-1">
                      <label htmlFor="dateOfBirth">
                        {getString("dateOfBirth")}
                      </label>
                      <DatePicker
                        locale="it"
                        className="form-control"
                        selected={values.dateOfBirth}
                        onChange={(date) => setFieldValue("dateOfBirth", date)}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <div className="invalid-feedback">
                        {errors.dateOfBirth &&
                          isConfirmed &&
                          errors.dateOfBirth}
                      </div>
                    </div>

                    <div className="col-md-6 mb-1">
                      <label htmlFor="luogoNascita">
                        {getString("luogoNascita")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="luogoNascita"
                        name="luogoNascita"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.luogoNascita}
                      />
                      <div className="invalid-feedback">
                        {errors.luogoNascita &&
                          isConfirmed &&
                          errors.luogoNascita}
                      </div>
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="CF">{getString("CF")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="CF"
                      name="CF"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.CF}
                    />
                    <div className="invalid-feedback">
                      {errors.CF && isConfirmed && errors.CF}
                      {errorMessageCF}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 mb-1">
                      <h3>Indirizzo di residenza *</h3>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-1">
                      <label htmlFor="address">{getString("address")} *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        placeholder={getString("address_placeholder")}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                      <div className="invalid-feedback">
                        {errors.address && isConfirmed && errors.address}
                      </div>
                    </div>

                    <div className="col-md-4 mb-1">
                      <label htmlFor="cap">{getString("cap")} *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="cap"
                        name="cap"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cap}
                      />
                      <div className="invalid-feedback">
                        {errors.cap && isConfirmed && errors.cap}
                      </div>
                    </div>

                    <div className="col-md-4 mb-1">
                      <label htmlFor="province">
                        {getString("province")} *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="province"
                        name="province"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.province}
                      />
                      <div className="invalid-feedback">
                        {errors.province && isConfirmed && errors.province}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        selectedComitato === "Covisoc" ? "none" : "block",
                    }}
                  >
                    <div className="row mt-2">
                      <div className="col-md-12 mb-1">
                        <h3>
                          Indicare il domicilio se diverso dalla residenza
                        </h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 mb-1">
                        <label htmlFor="address2">{getString("address")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address2"
                          name="address2"
                          placeholder={getString("address_placeholder")}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address2}
                        />
                        <div className="invalid-feedback">
                          {errors.address2 && isConfirmed && errors.address2}
                        </div>
                      </div>

                      <div className="col-md-4 mb-1">
                        <label htmlFor="cap2">{getString("cap")}</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cap2"
                          name="cap2"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.cap2}
                        />
                        <div className="invalid-feedback">
                          {errors.cap2 && isConfirmed && errors.cap2}
                        </div>
                      </div>

                      <div className="col-md-4 mb-1">
                        <label htmlFor="province2">
                          {getString("province")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="province2"
                          name="province2"
                          placeholder=""
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.province2}
                        />
                        <div className="invalid-feedback">
                          {errors.province2 && isConfirmed && errors.province2}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-1">
                    <label htmlFor="tel1">{getString("tel1")}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="tel1"
                      name="tel1"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel1}
                    />
                    <div className="invalid-feedback">
                      {errors.tel1 && isConfirmed && errors.tel1}
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="tel2" style={{ marginBottom: "0px" }}>
                      {getString("tel2")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tel2"
                      name="tel2"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tel2}
                    />
                    <div className="invalid-feedback">
                      {errors.tel2 && isConfirmed && errors.tel2}
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="email">{getString("email")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className="invalid-feedback">
                      {errors.email && isConfirmed && errors.email}
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="email2">{getString("email2")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email2"
                      name="email2"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email2}
                    />
                    <div className="invalid-feedback">
                      {errors.email2 && isConfirmed && errors.email2}
                    </div>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="pec">{getString("pec")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="pec"
                      name="pec"
                      placeholder=""
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pec}
                    />
                    <div className="invalid-feedback">
                      {errors.pec && isConfirmed && errors.pec}
                    </div>
                  </div>

                  <div className="row mt-2">
                    {specialComitato ? null : (
                      <div className="col-md-12 mb-1">
                        <h3>
                          Organo di giustizia territoriale di interesse e
                          requisiti
                        </h3>
                        <h4>
                          Riferimento art. 35, comma 4, dello Statuto Federale
                        </h4>
                        <p>
                          Possono essere nominati Giudici sportivi territoriali
                          e componenti della Corte sportiva di appello e del
                          Tribunale federale a livello territoriale, coloro che
                          siano:
                        </p>
                        <ul>
                          <li type="a">laureati in giurisprudenza;</li>
                          <li type="a">
                            diplomati delle scuole superiori che siano stati
                            tesserati per la FIGC per almeno tre anni;
                          </li>
                          <li type="a">
                            diplomati delle scuole superiori che abbiano
                            maturato almeno cinque anni di esperienza
                            nell’ordinamento sportivo.
                          </li>
                        </ul>
                      </div>
                    )}
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display: selectedComitato === "TFN" ? "block" : "none",
                      }}
                    >
                      <h3>
                        Requisiti di cui all'art.34, comma 14, dello Statuto
                        Federale
                      </h3>
                      <p>
                        Possono essere nominati componente aggiunto del
                        Tribunale Federale Nazionale - Sezione Disciplinare
                        coloro che siano in possesso di competenze specifiche in
                        materia gestionale, economica-aziendale e tributaria{" "}
                      </p>
                    </div>
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display: selectedComitato === "CFA" ? "block" : "none",
                      }}
                    >
                      <h3>
                        Requisiti di cui all'art.35, comma 1, dello Statuto
                        Federale
                      </h3>
                      <p>
                        Possono essere nominati componenti della Corte Federale
                        di appello coloro che siano:
                      </p>
                      <ul>
                        <li type="a">
                          Professori universitari di ruolo in materie
                          giuridiche, anche a riposo;
                        </li>
                        <li type="a">
                          Magistrati di qualsiasi giurisdizione, anche a riposo;
                        </li>
                        <li type="a">
                          Avvocati dello Stato, anche a riposo e notai;
                        </li>
                        <li type="a">
                          Avvocati iscritti da almeno cinque anni negli albi dei
                          relativi consigli dell'ordine;
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display:
                          selectedComitato === "CFAv1" ? "block" : "none",
                      }}
                    >
                      <h3>
                        Requisiti di cui all'art.35, comma 1, dello Statuto
                        Federale
                      </h3>
                      <p>
                        Possono essere nominati componenti della Corte Federale
                        di appello coloro che siano:
                      </p>
                      <ul>
                        <li type="a">
                          Professori universitari di ruolo in materie
                          giuridiche, anche a riposo;
                        </li>
                        <li type="a">
                          Magistrati di qualsiasi giurisdizione, anche a riposo;
                        </li>
                        <li type="a">
                          Avvocati dello Stato, anche a riposo e notai;
                        </li>
                        <li type="a">
                          Avvocati iscritti da almeno cinque anni negli albi dei
                          relativi consigli dell'ordine;
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display:
                          selectedComitato === "TFNv1" ? "block" : "none",
                      }}
                    >
                      <h3>
                        Requisiti di cui all'art.35, comma 1, dello Statuto
                        Federale
                      </h3>
                      <p>
                        Possono essere nominati Giudici Sportivi Nazionali
                        coloro che siano:
                      </p>
                      <ul>
                        <li type="a">
                          Professori universitari di ruolo in materie
                          giuridiche, anche a riposo;
                        </li>
                        <li type="a">
                          Magistrati di qualsiasi giurisdizione, anche a riposo;
                        </li>
                        <li type="a">
                          Avvocati dello Stato, anche a riposo e notai;
                        </li>
                        <li type="a">
                          Avvocati iscritti da almeno cinque anni negli albi dei
                          relativi consigli dell'ordine;
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display:
                          selectedComitato === "SostPF" ? "block" : "none",
                      }}
                    >
                      <h3>
                        Requisiti di cui all'art.35, comma 3, dello Statuto
                        Federale
                      </h3>
                      <p>
                        Possono essere nominati Sostituto Procuratore Federale
                        coloro che siano:
                      </p>
                      <ul>
                        <li type="a">
                          Professori universitari di ruolo in materie
                          giuridiche, anche a riposo;
                        </li>
                        <li type="a">
                          Magistrati di qualsiasi giurisdizione, anche a riposo;
                        </li>
                        <li type="a">
                          Avvocati dello Stato, anche a riposo e notai;
                        </li>
                        <li type="a">
                          Avvocati iscritti da almeno cinque anni negli albi dei
                          relativi consigli dell'ordine;
                        </li>
                        <li type="a">
                          Iscritti all'albo dei dottori commercialisti e degli
                          esperti contabili;
                        </li>
                        <li type="a">
                          Ufficiali delle forze dell'ordine, anche a riposo;
                        </li>
                        <li type="a">
                          Laureati in materie giuridiche, con due anni di
                          esperienza nell'ordinamento sportivo;
                        </li>
                      </ul>
                    </div>
                    <div
                      className="col-md-12 mb-1"
                      style={{
                        display:
                          selectedComitato === "Covisoc" ? "block" : "none",
                      }}
                    >
                      {formType === "Safe" ? (
                        <h3>
                          Requisiti di cui all'art.8, comma 4, del Regolamento
                          FIGC per la prevenzione e il contrasto di abusi,
                          violenze e discriminazioni
                        </h3>
                      ) : (
                        <h3>
                          Requisiti di cui all'art.36, comma 3, dello Statuto
                          Federale
                        </h3>
                      )}
                      {formType === "Safe" ? (
                        <p>
                          Possono essere nominati Componenti della Commissione
                          Federale Responsabile delle Politiche di Safeguarding
                          coloro che, in possesso di specifica competenza e
                          indiscussa moralità e indipendenza, non incorrano in
                          nessuna delle fattispecie previste dall’art. 29, comma
                          3, dello Statuto Federale e dall’art. 11 del Codice di
                          Comportamento Sportivo del CONI, e che siano in
                          possesso di comprovata esperienza in materia
                          giuridico-legale, medico-sanitaria, o in ambito
                          sociale, psicologico o sportivo.
                        </p>
                      ) : (
                        <p>
                          Possono essere nominati Componenti della Covisoc
                          coloro che,in possesso di specifica competenza e
                          indiscussa moralità e indipendenza, siano:
                        </p>
                      )}
                      {formType !== "Safe" && (
                        <ul>
                          <li type="a">
                            Docenti universitari di ruolo in materie giuridiche
                            e economico-aziendali, anche a riposo;
                          </li>
                          <li type="a">
                            Magistrati di qualsiasi giurisdizione, anche a
                            riposo;
                          </li>
                          <li type="a">
                            Dottori commercialisti, avvocati, notai, avvocati
                            dello stato o consulenti del lavoro laureati in
                            economia e commercio con almeno dieci anni di
                            anziantià nella funzione, anche a riposo;
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  {specialComitato ? null : (
                    <div className="row mt-2">
                      <div className="col-md-12 mb-1">
                        <label htmlFor="organo">{getString("organo")}</label>
                        {/* <div className="input-group">
                      <select className="custom-select" id="organo" required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organo}
                      >
                        <option value="">{getString("select_default")}</option>
                        {selectLists[lang].organi.map(item => {
                          return (
                            <option key={item.key} value={item.key}>{item.label}</option>
                          )
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors.organo && isConfirmed && errors.organo}
                      </div>
                    </div> */}
                      </div>
                    </div>
                  )}

                  {specialComitato ? null : (
                    <div className="mb-1">
                      {/* <label htmlFor="qualeIncarico">{getString("ostative")}</label> */}
                      {selectedComitato !== "AOSTA" ? (
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="organo1"
                              name="organo1"
                              onChange={(event) => {
                                setFieldValue(
                                  "organo1",
                                  event.currentTarget.checked
                                );
                                setFieldValue("organo", "");
                                if (
                                  event.currentTarget.checked &&
                                  values.organo2
                                ) {
                                  setFieldValue(
                                    "organo",
                                    "CSA/TFT-e-Giudice-Sportivo"
                                  );
                                } else if (event.currentTarget.checked) {
                                  setFieldValue("organo", "CSA/TFT");
                                } else if (values.organo2) {
                                  setFieldValue("organo", "Giudice-Sportivo");
                                }
                              }}
                              onBlur={handleBlur}
                              checked={values.organo1}
                            />
                            {selectLists[lang].organi[0].label}
                          </label>
                        </div>
                      ) : null}
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            id="organo2"
                            name="organo2"
                            onChange={(event) => {
                              setFieldValue(
                                "organo2",
                                event.currentTarget.checked
                              );
                              setFieldValue("organo", "");
                              setFieldValue("delegazione", "");
                              if (
                                values.organo1 &&
                                event.currentTarget.checked
                              ) {
                                setFieldValue(
                                  "organo",
                                  "CSA/TFT-e-Giudice-Sportivo"
                                );
                              } else if (values.organo1) {
                                setFieldValue("organo", "CSA/TFT");
                              } else if (event.currentTarget.checked) {
                                setFieldValue("organo", "Giudice-Sportivo");
                              }
                            }}
                            onBlur={handleBlur}
                            checked={values.organo2}
                            disabled={selectedComitato === "AOSTA"}
                          />
                          {selectLists[lang].organi[1].label}
                        </label>
                      </div>
                      <div className="invalid-feedback">
                        {errors.organo && isConfirmed && errors.organo}
                      </div>
                    </div>
                  )}

                  {specialComitato ? null : (
                    <div className="row mt-2">
                      <div className="col-md-12 mb-1">
                        <label htmlFor="delegazione">
                          {getString("delegazione")}
                        </label>
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="delegazione"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.delegazione}
                            disabled={
                              values.organo === "" ||
                              values.organo === "CSA/TFT"
                            }
                          >
                            <option value="">
                              {getString("select_default")}
                            </option>
                            {selectedComitato === "BOLZANO" ||
                            selectedComitato === "TRENTO" ? (
                              <option value={`CPA${selectedComitato}`}>
                                CPA {selectedComitato}
                              </option>
                            ) : selectedComitato !== "AOSTA" ? (
                              <option value={`CR${selectedComitato}`}>
                                CR {selectedComitato}
                              </option>
                            ) : null}
                            {selectLists[lang].comitati[selectedComitato].map(
                              (item) => {
                                return (
                                  <option key={item.key} value={item.key}>
                                    {item.label}
                                  </option>
                                );
                              }
                            )}
                          </select>
                          <div className="invalid-feedback">
                            {errors.delegazione &&
                              isConfirmed &&
                              errors.delegazione}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedComitato === "CFA" ||
                  selectedComitato === "TFN" ||
                  formType === "Safe" ? null : (
                    <div className="row mt-2">
                      <div className="col-md-12 mb-1">
                        <label htmlFor="requisiti">
                          {selectedComitato === "Covisoc"
                            ? getString("requisiti2")
                            : getString("requisiti")}
                        </label>
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="requisiti"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.requisiti}
                          >
                            {selectedComitato === "CFAv1" ? (
                              <>
                                <option value="">
                                  {getString("select_default")}
                                </option>
                                {selectLists[lang].requisitiNominaCFA.map(
                                  (item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            ) : selectedComitato === "TFNv1" ? (
                              <>
                                <option value="">
                                  {getString("select_default")}
                                </option>
                                {selectLists[lang].requisitiNominaCFA.map(
                                  (item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            ) : selectedComitato === "SostPF" ? (
                              <>
                                <option value="">
                                  {getString("select_default")}
                                </option>
                                {selectLists[lang].requisitiNominaSostPF.map(
                                  (item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            ) : selectedComitato === "Covisoc" ? (
                              <>
                                <option value="">
                                  {getString("select_default")}
                                </option>
                                {selectLists[lang].requisitiNominaCovisoc.map(
                                  (item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <>
                                <option value="">
                                  {getString("select_default")}
                                </option>
                                {selectLists[lang].requisitiNomina.map(
                                  (item) => {
                                    return (
                                      <option key={item.key} value={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </select>
                          <div className="invalid-feedback">
                            {errors.requisiti &&
                              isConfirmed &&
                              errors.requisiti}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedComitato !== "Covisoc" && formType !== "Safe" && (
                    <div className="mb-1">
                      {selectedComitato === "TFN" ||
                      selectedComitato === "CFA" ? (
                        <label htmlFor="competenza1">
                          {getString("competenze1")}
                        </label>
                      ) : (
                        <label htmlFor="competenza">
                          {getString("competenze")}
                        </label>
                      )}
                      <textarea
                        type="text"
                        className="form-control"
                        id="competenza"
                        name="competenza"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.competenza}
                      />
                      <div className="invalid-feedback">
                        {errors.competenza && isConfirmed && errors.competenza}
                      </div>
                    </div>
                  )}
                  {selectedComitato !== "Covisoc" && (
                    <div className="row mt-2">
                      <div className="col-md-12 mb-1">
                        <label htmlFor="incarichiAttuali">
                          {getString("incarichiAttuali")}
                        </label>
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="incarichiAttuali"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.incarichiAttuali}
                          >
                            <option value="">
                              {getString("select_default")}
                            </option>
                            <option key="si" value="si">
                              Si
                            </option>
                            <option key="no" value="no">
                              No
                            </option>
                          </select>
                          <div className="invalid-feedback">
                            {errors.incarichiAttuali &&
                              isConfirmed &&
                              errors.incarichiAttuali}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {values.incarichiAttuali === "si" ? (
                    <div className="mb-1">
                      <label htmlFor="qualeIncarico">
                        {getString("qualeIncarico")}
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="qualeIncarico"
                        name="qualeIncarico"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.qualeIncarico}
                      />
                      <div className="invalid-feedback">
                        {errors.qualeIncarico &&
                          isConfirmed &&
                          errors.qualeIncarico}
                      </div>
                    </div>
                  ) : null}

                  <h3>Dichiarazioni</h3>
                  <h4>
                    Il sottoscritto, manifestando la propria disponibilità a
                    ricoprire l’incarico, dichiara:
                  </h4>

                  <div className="mb-1">
                    {/* <label htmlFor="qualeIncarico">{getString("ostative")}</label> */}
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="ostative1"
                          name="ostative1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ostative1}
                        />
                        {formType === "Safe"
                          ? getString("ostative8")
                          : selectedComitato === "Covisoc"
                          ? getString("ostative6")
                          : selectedComitato === "CFAv1" ||
                            selectedComitato === "TFNv1"
                          ? getString("ostative4")
                          : getString(
                              selectedComitato === "CFA" ||
                                selectedComitato === "TFN"
                                ? "ostative3"
                                : formType === "CRC"
                                ? "ostative5"
                                : "ostative1"
                            )}
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {errors.ostative1 && isConfirmed && errors.ostative1}
                    </div>
                    {formType !== "Safe" && (
                      <>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="ostative2"
                              name="ostative2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ostative2}
                            />
                            {selectedComitato === "Covisoc"
                              ? getString("ostative7")
                              : getString("ostative2")}
                          </label>
                        </div>
                        <div className="invalid-feedback">
                          {errors.ostative2 && isConfirmed && errors.ostative2}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 mb-1">
                      <label htmlFor="condanne">{formType === "Safe" ? getString("condanne1") : getString("condanne")}</label>
                      {formType === "Safe"
                        ? selectLists[lang].condanne1.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="condanne"
                                  id={`condanne${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`condanne${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })
                        : selectLists[lang].condanne.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="condanne"
                                  id={`condanne${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`condanne${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })}
                      <div className="invalid-feedback">
                        {errors.condanne && isConfirmed && errors.condanne}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 mb-1">
                      <label htmlFor="misure">{formType === "Safe" ? getString("misure1") : getString("misure")}</label>
                      {formType === "Safe"
                        ? selectLists[lang].misure1.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="misure"
                                  id={`misure${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`misure${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })
                        : selectLists[lang].misure.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="misure"
                                  id={`misure${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`misure${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })}
                      <div className="invalid-feedback">
                        {errors.misure && isConfirmed && errors.misure}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12 mb-1">
                      <label htmlFor="condizioni">
                        {formType === "Safe" ? getString("condizioni1") : getString("condizioni")}
                      </label>
                      {formType === "Safe"
                        ? selectLists[lang].condizioni1.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="condizioni"
                                  id={`condizioni${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`condizioni${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })
                        : selectLists[lang].condizioni.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="condizioni"
                                  id={`condizioni${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`condizioni${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })}
                      <div className="invalid-feedback">
                        {errors.condizioni && isConfirmed && errors.condizioni}
                      </div>
                    </div>
                  </div>

                  {values.condizioni === "Si-art-51" ? (
                    <div className="mb-1">
                      <label htmlFor="condizioniInfo">
                        {getString("condizioniInfo")}
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="condizioniInfo"
                        name="condizioniInfo"
                        placeholder=""
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.condizioniInfo}
                      />
                      <div className="invalid-feedback">
                        {errors.condizioniInfo &&
                          isConfirmed &&
                          errors.condizioniInfo}
                      </div>
                    </div>
                  ) : null}

                  <div className="row mt-2">
                    <div className="col-md-12 mb-1">
                      <label htmlFor="rapportoPA">
                        {formType === "Safe" ? getString("rapportoPA1") : getString("rapportoPA")}
                      </label>
                      {formType === "Safe"
                        ? selectLists[lang].pa1.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="rapportoPA"
                                  id={`rapportoPA${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`rapportoPA${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })
                        : selectLists[lang].pa.map((item) => {
                            return (
                              <div className="form-check" key={item.key}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="rapportoPA"
                                  id={`rapportoPA${item.key}`}
                                  value={item.key}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`rapportoPA${item.key}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            );
                          })}
                      <div className="invalid-feedback">
                        {errors.rapportoPA && isConfirmed && errors.rapportoPA}
                      </div>
                    </div>
                  </div>

                  {/* <h3>Altre dichiarazioni</h3>
                <h4>La mancata sottoscrizione di una delle seguenti dichiarazione impedisce la valutazione della manifestazione di interesse presentata *</h4> */}

                  <div className="mb-1">
                    {/* <label htmlFor="qualeIncarico">{getString("ostative")}</label> */}
                    <div className="checkbox">
                      {selectedComitato === "Covisoc" ? (
                        formType === "Safe" ? (
                          <>
                            <label>
                              <input
                                type="checkbox"
                                id="altreDichiarazioni1"
                                name="altreDichiarazioni1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.altreDichiarazioni1}
                              />
                              &nbsp;Il sottoscritto, in caso di nomina, si
                              obbliga a comunicare senza indugio alla F.I.G.C.,
                              il sopravvenire di situazione di incompatibilità
                              e/o il venir meno dei requisiti alla nomina,
                              nonché il sopravvenire di ogni situazione che
                              rientrasse nelle ipotesi di cui ai punti 2.b),
                              3.b), 4.b) e 5.b).
                            </label>
                          </>
                        ) : (
                          <label>
                            <input
                              type="checkbox"
                              id="altreDichiarazioni1"
                              name="altreDichiarazioni1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.altreDichiarazioni1}
                            />
                            &nbsp;Il sottoscritto, in caso di nomina, si obbliga
                            a comunicare senza indugio alla F.I.G.C., il
                            sopravvenire di situazione di incompatibilità e/o il
                            venir meno dei requisiti alla nomina, nonché il
                            sopravvenire di ogni situazione che rientrasse nelle
                            ipotesi di cui ai punti 3.b), 4.b), 5.b) e 6.b).
                          </label>
                        )
                      ) : (
                        <label>
                          <input
                            type="checkbox"
                            id="altreDichiarazioni1"
                            name="altreDichiarazioni1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.altreDichiarazioni1}
                          />
                          &nbsp;Il sottoscritto, in caso di nomina, si obbliga a
                          comunicare senza indugio alla F.I.G.C., alla
                          Commissione Federale di Garanzia ed al Presidente
                          dell’Organo di giustizia del quale è componente, il
                          sopravvenire di situazione di incompatibilità e/o il
                          venir meno dei requisiti alla nomina nell’organo di
                          giustizia sportiva, nonché il sopravvenire di ogni
                          situazione che rientrasse nelle ipotesi di cui ai
                          punti 3.b), 4.b), 5.b) e 6.b).
                        </label>
                      )}
                    </div>
                    <div className="invalid-feedback">
                      {errors.altreDichiarazioni1 &&
                        isConfirmed &&
                        errors.altreDichiarazioni1}
                    </div>
                    <div className="checkbox">
                      {selectedComitato === "Covisoc" ? (
                        <label>
                          <input
                            type="checkbox"
                            id="altreDichiarazioni2"
                            name="altreDichiarazioni2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.altreDichiarazioni2}
                          />
                          {formType === "Safe" ? (
                            <>
                              &nbsp;Lo scrivente si impegna sin d’ora alla
                              osservanza di ogni disposizione di legge, federale
                              e comunque dell’ordinamento sportivo, ed in
                              particolare alla osservanza dell’obbligo di
                              riservatezza e di quello di partecipazione alle
                              attività della commissione
                            </>
                          ) : (
                            <>
                              &nbsp;Lo scrivente si impegna sin d’ora alla
                              osservanza di ogni disposizione di legge, federale
                              e comunque dell’ordinamento sportivo, ed in
                              particolare alla osservanza dell’obbligo di
                              riservatezza e di quello di partecipazione alle
                              attività della Covisoc, nonché a non rilasciare
                              dichiarazioni agli organi di stampa e ad altri
                              mezzi di comunicazione in ordine alle attività del
                              proprio ufficio.
                            </>
                          )}
                        </label>
                      ) : (
                        <label>
                          <input
                            type="checkbox"
                            id="altreDichiarazioni2"
                            name="altreDichiarazioni2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.altreDichiarazioni2}
                          />
                          &nbsp;Lo scrivente si impegna sin d’ora alla
                          osservanza di ogni disposizione di legge, federale e
                          comunque dell’ordinamento sportivo, ed in particolare
                          alla osservanza dell’obbligo di riservatezza e di
                          quello di partecipazione alle attività di detti
                          organi, nonché a non rilasciare dichiarazioni agli
                          organi di stampa e ad altri mezzi di comunicazione in
                          ordine ai processi in corso o a quelli nei quali fosse
                          chiamato a pronunciarsi.
                        </label>
                      )}
                    </div>
                    {formType !== "Safe" && (
                      <>
                        <div className="invalid-feedback">
                          {errors.altreDichiarazioni2 &&
                            isConfirmed &&
                            errors.altreDichiarazioni2}
                        </div>
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="altreDichiarazioni6"
                              name="altreDichiarazioni6"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.altreDichiarazioni6}
                            />
                            &nbsp;Lo scrivente si impegna ad osservare il
                            divieto di avere rapporti di qualsiasi natura con le
                            società soggette alla vigilanza della Covisoc e ad
                            osservare tale divieto per l'intero anno successivo
                            alla cessazione dell'incarico.
                          </label>
                        </div>
                      </>
                    )}
                    <div className="invalid-feedback">
                      {errors.altreDichiarazioni6 &&
                        isConfirmed &&
                        errors.altreDichiarazioni6}
                    </div>
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="altreDichiarazioni3"
                          name="altreDichiarazioni3"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.altreDichiarazioni3}
                        />
                        &nbsp;Lo scrivente si impegna a presentare apposita
                        dichiarazione di accettazione della nomina.
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {errors.altreDichiarazioni3 &&
                        isConfirmed &&
                        errors.altreDichiarazioni3}
                    </div>
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="altreDichiarazioni4"
                          name="altreDichiarazioni4"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.altreDichiarazioni4}
                        />
                        &nbsp;Le dichiarazioni contenute nella presente sono
                        state rilasciate dal sottoscritto assumendosi ogni
                        responsabilità penale e civile in caso di mendace
                        dichiarazione
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {errors.altreDichiarazioni4 &&
                        isConfirmed &&
                        errors.altreDichiarazioni4}
                    </div>
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="altreDichiarazioni5"
                          name="altreDichiarazioni5"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.altreDichiarazioni5}
                        />
                        &nbsp;Ai sensi e per gli effetti del Regolamento Europeo
                        n. 679/2016, il sottoscritto presta il proprio consenso
                        al trattamento dei dati personali forniti.
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {errors.altreDichiarazioni5 &&
                        isConfirmed &&
                        errors.altreDichiarazioni5}
                    </div>
                  </div>

                  <label htmlFor="cvAttachment">
                    {getString("cvAttachment")} *
                  </label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="cvAttachment"
                        accept=".pdf"
                        onChange={(event) => {
                          setFieldValue("cvAttachment", event.target.files[0]);
                        }}
                      />
                      {values.cvAttachment ? (
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          {values.cvAttachment.name}
                        </label>
                      ) : (
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          Choose file (PDF, Max. 2 MB)
                        </label>
                      )}
                    </div>
                    <div className="invalid-feedback">
                      {errors.cvAttachment &&
                        isConfirmed &&
                        errors.cvAttachment}
                      {errorMessage}
                    </div>
                  </div>

                  <div className="custom-control custom-checkbox pt-4">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="privacypolicy"
                      name="privacypolicy"
                      value="agreed"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.privacypolicy}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="privacypolicy"
                    >
                      {getString("pp1")}&nbsp;
                      {lang === "it" ? (
                        <a
                          href="https://www.figc.it/it/privacy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      ) : (
                        <a
                          href="https://www.figc.it/it/privacy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      )}
                      . *
                    </label>
                    <div className="invalid-feedback">
                      {errors.privacypolicy &&
                        isConfirmed &&
                        errors.privacypolicy}
                    </div>
                  </div>

                  {selectedComitato === "Covisoc" ? (
                    <div className="mb-1">
                      I dati acquisiti saranno trattati per il processo di
                      selezione dalla Segreteria Generale
                      <br />I dati saranno mantenuti sulla piattaforma per un
                      periodo non superiore a 6 mesi. FIGC provvederà a
                      cancellarli definitivamente alla scadenza del corrente
                      quadriennio olimpico.
                    </div>
                  ) : selectedComitato === "CFAv1" ||
                    selectedComitato === "TFNv1" ||
                    selectedComitato === "SostPF" ? (
                    <div
                      className="mb-1"
                      style={{
                        display:
                          selectedComitato === "CFA" ||
                          selectedComitato === "TFN"
                            ? "none"
                            : "block",
                      }}
                    >
                      I dati acquisiti saranno trattati per il processo di
                      selezione dalla Segreteria Generale e dalla Commissione
                      Federale di Garanzia
                      <br />I dati saranno mantenuti sulla piattaforma per un
                      periodo non superiore a 6 mesi. FIGC provvederà a
                      cancellarli definitivamente alla scadenza del corrente
                      quadriennio olimpico.
                    </div>
                  ) : (
                    <div
                      className="mb-1"
                      style={{
                        display:
                          selectedComitato === "CFA" ||
                          selectedComitato === "TFN"
                            ? "none"
                            : "block",
                      }}
                    >
                      I dati acquisiti saranno trattati per il processo di
                      selezione dalla Segreteria Generale e dalla Commissione di
                      valutazione
                      <span
                        style={{
                          display: formType === "CRC" ? "none" : "block",
                        }}
                      >
                        {" "}
                        e saranno resi accessibili attraverso la piattaforma
                        anche a LND
                      </span>
                      .<br />I dati saranno mantenuti sulla piattaforma per un
                      periodo non superiore a 6 mesi. FIGC provvederà a
                      cancellarli definitivamente alla scadenza del corrente
                      quadriennio olimpico.
                    </div>
                  )}
                  <div
                    className="mb-1"
                    style={{
                      display:
                        selectedComitato === "CFA" || selectedComitato === "TFN"
                          ? "block"
                          : "none",
                    }}
                  >
                    I dati acquisiti saranno trattati per il processo di
                    selezione dalla Segreteria Generale e dalla Commissione di
                    valutazione.
                    <br />I dati saranno mantenuti sulla piattaforma per un
                    periodo non superiore a 6 mesi. FIGC provvederà a
                    cancellarli definitivamente alla scadenza del corrente
                    quadriennio olimpico.
                  </div>

                  <div className="mb-1">
                    <label className="text-muted">
                      {getString("required_field")}
                    </label>
                  </div>

                  <button
                    className="btn btn-outline-primary btn-lg btn-block my-3"
                    type="button"
                    onClick={() => {
                      console.log(isValid);
                      setIsConfirmed(true);
                      if (isValid) {
                        setStepNumber(2);
                      }
                    }}
                  >
                    {getString("proceed")}
                  </button>
                </div>

                <h4
                  className="mb-3"
                  id="payment-title"
                  style={{ display: stepNumber === 2 ? "flex" : "none" }}
                >
                  {getString("title2")}
                </h4>

                <div
                  className="row mb-1"
                  id="payment-summary"
                  style={{ display: stepNumber === 2 ? "flex" : "none" }}
                >
                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">
                      {getString("firstName")}
                    </label>
                    <p>{values.firstName}</p>
                  </div>
                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">
                      {getString("lastName")}
                    </label>
                    <p>{values.lastName}</p>
                  </div>

                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">
                      {getString("dateOfBirth")}
                    </label>
                    <p>{moment(values.dateOfBirth).format("DD/MM/YYYY")}</p>
                  </div>

                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">
                      {getString("luogoNascita")}
                    </label>
                    <p>{values.luogoNascita}</p>
                  </div>

                  <div className="col-md-12 mb-1 mt-3">
                    <label className="text-muted">{getString("CF")}</label>
                    <p>{values.CF}</p>
                  </div>

                  <div className="col-md-12 mb-1 mt-3">
                    <h3>Indirizzo di residenza *</h3>
                  </div>

                  <div className="col-md-4 mb-1 mt-3">
                    <label className="text-muted">{getString("address")}</label>
                    <p>{values.address}</p>
                  </div>
                  <div className="col-md-4 mb-1 mt-3">
                    <label className="text-muted">{getString("cap")}</label>
                    <p>{values.cap}</p>
                  </div>
                  <div className="col-md-4 mb-1 mt-3">
                    <label className="text-muted">
                      {getString("province")}
                    </label>
                    <p>{values.province}</p>
                  </div>

                  {selectedComitato !== "Covisoc" && (
                    <>
                      <div className="col-md-12 mb-1 mt-3">
                        <h3>
                          Indicare il domicilio se diverso dalla residenza
                        </h3>
                      </div>

                      <div className="col-md-4 mb-1 mt-3">
                        <label className="text-muted">
                          {getString("address")}
                        </label>
                        <p>{values.address2}</p>
                      </div>
                      <div className="col-md-4 mb-1 mt-3">
                        <label className="text-muted">{getString("cap")}</label>
                        <p>{values.cap2}</p>
                      </div>
                      <div className="col-md-4 mb-1 mt-3">
                        <label className="text-muted">
                          {getString("province")}
                        </label>
                        <p>{values.province2}</p>
                      </div>
                    </>
                  )}

                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">{getString("tel1")}</label>
                    <p>{values.tel1}</p>
                  </div>

                  <div className="col-md-6 mb-1 mt-3">
                    <label className="text-muted">{getString("tel2")}</label>
                    <p>{values.tel2}</p>
                  </div>

                  <div className="col-md-12 mb-1 mt-3">
                    <label className="text-muted">{getString("email")}</label>
                    <p>{values.email}</p>
                  </div>

                  <div className="col-md-12 mb-1 mt-3">
                    <label className="text-muted">{getString("pec")}</label>
                    <p>{values.pec}</p>
                  </div>

                  {selectedComitato === "Covisoc" ? (
                    <div className="col-md-12 mb-1 mt-3">
                      <h3>
                        Organo di giustizia territoriale di interesse e
                        requisiti
                      </h3>
                      <h4>
                        Riferimento art. 36, comma 3, dello Statuto Federale
                      </h4>
                      <p>
                        Possono essere nominati componenti della Covisoc coloro
                        che,in possesso di specifica competenza e indiscussa
                        moralità e indipendenza, siano:
                      </p>
                      <ul>
                        <li type="a">
                          Docenti universitari di ruolo in materie giuridiche ed
                          economico – aziendali, anche a riposo;
                        </li>
                        <li type="a">
                          Magistrati di qualsiasi giurisdizione, anche a riposo;
                        </li>
                        <li type="a">
                          Dottori commercialisti, avvocati, notai, avvocati
                          dello Stato o consulenti del lavoro laureati in
                          economia e commercio con almeno dieci anni di
                          anzianità nella funzione, anche a riposo
                        </li>
                      </ul>
                    </div>
                  ) : selectedComitato === "CFA" ||
                    selectedComitato === "TFN" ? null : (
                    <div className="col-md-12 mb-1 mt-3">
                      <h3>
                        Organo di giustizia territoriale di interesse e
                        requisiti
                      </h3>
                      <h4>
                        Riferimento art. 35, comma 4, dello Statuto Federale
                      </h4>
                      <p>
                        Possono essere nominati Giudici sportivi territoriali e
                        componenti della Corte sportiva di appello e del
                        Tribunale federale a livello territoriale, coloro che
                        siano:
                      </p>
                      <ul>
                        <li type="a">laureati in giurisprudenza;</li>
                        <li type="a">
                          diplomati delle scuole superiori che siano stati
                          tesserati per la FIGC per almeno tre anni;
                        </li>
                        <li type="a">
                          diplomati delle scuole superiori che abbiano maturato
                          almeno cinque anni di esperienza nell’ordinamento
                          sportivo.
                        </li>
                      </ul>
                    </div>
                  )}
                  <div
                    className="col-md-12 mb-1"
                    style={{
                      display: selectedComitato === "TFN" ? "block" : "none",
                    }}
                  >
                    <h3>
                      Requisiti di cui all'art.34, comma 14, dello Statuto
                      Federale
                    </h3>
                    <p>
                      Possono essere nominati componente aggiunto del Tribunale
                      Federale Nazionale - Sezione Disciplinare coloro che siano
                      in possesso di competenze specifiche in materia
                      gestionale, economica-aziendale e tributaria{" "}
                    </p>
                  </div>
                  <div
                    className="col-md-12 mb-1"
                    style={{
                      display: selectedComitato === "CFA" ? "block" : "none",
                    }}
                  >
                    <h3>
                      Requisiti di cui all'art.34, comma 14, dello Statuto
                      Federale
                    </h3>
                    <p>
                      Possono essere nominati componente aggiunto della Corte
                      Federale di Appello coloro che siano in possesso di
                      competenze specifiche in materia gestionale,
                      economica-aziendale e tributaria{" "}
                    </p>
                  </div>
                  <div
                    className="col-md-12 mb-1"
                    style={{
                      display: selectedComitato === "CFAv1" ? "block" : "none",
                    }}
                  >
                    <h3>
                      Requisiti di cui all'art.34, comma 14, dello Statuto
                      Federale
                    </h3>
                    <p>
                      Possono essere nominati componente aggiunto della Corte
                      Federale di Appello coloro che siano in possesso di
                      competenze specifiche in materia gestionale,
                      economica-aziendale e tributaria{" "}
                    </p>
                  </div>
                  <div
                    className="col-md-12 mb-1"
                    style={{
                      display: selectedComitato === "TFNv1" ? "block" : "none",
                    }}
                  >
                    <h3>
                      Requisiti di cui all'art.34, comma 14, dello Statuto
                      Federale
                    </h3>
                    <p>
                      Possono essere nominati componente aggiunto della Corte
                      Federale di Appello coloro che siano in possesso di
                      competenze specifiche in materia gestionale,
                      economica-aziendale e tributaria{" "}
                    </p>
                  </div>

                  {selectedComitato === "TFN" ||
                  selectedComitato === "CFA" ||
                  formType === "CFA" ||
                  selectedComitato === "Covisoc" ? null : (
                    <div className="col-md-12 mb-1 mt-3">
                      <label className="text-muted">
                        {getString("organo")}
                      </label>
                      <p>
                        {fromKeyToLabel(selectLists.it.organi, values.organo)}
                      </p>
                    </div>
                  )}

                  {/* <div className="col-md-12 mb-1 mt-3">
                  <label className="text-muted">{getString("comitato")}</label>
                  <p>{values.comitato}</p>
                </div> */}

                  {selectedComitato === "CFA" ||
                  selectedComitato === "TFN" ||
                  formType === "Safe" ? null : (
                    <div className="col-md-12 mb-1 mt-3">
                      <label className="text-muted">
                        {selectedComitato === "Covisoc"
                          ? getString("requisiti2")
                          : getString("requisiti")}
                      </label>
                      {selectedComitato === "Covisoc" && (
                        <p>{values.requisiti}</p>
                      )}
                    </div>
                  )}

                  {selectedComitato !== "Covisoc" && (
                    <>
                      {" "}
                      <div className="col-md-12 mb-1 mt-3">
                        <label className="text-muted">
                          {getString(
                            selectedComitato === "TFN" ||
                              selectedComitato === "CFA"
                              ? "competenze1"
                              : "competenze"
                          )}
                        </label>
                        <p>{values.competenza}</p>
                      </div>
                      <div className="col-md-12 mb-1 mt-3">
                        <label className="text-muted">
                          {getString("incarichiAttuali")}
                        </label>
                        <p>{values.incarichiAttuali}</p>
                      </div>
                      <div className="col-md-12 mb-1 mt-3">
                        <label className="text-muted">
                          {getString("qualeIncarico")}
                        </label>
                        <p>{values.qualeIncarico}</p>
                      </div>
                    </>
                  )}

                  <div className="col-md-12 mb-1 mt-3">
                    <h3>Dichiarazioni</h3>
                    <h4>
                      Il sottoscritto, manifestando la propria disponibilità a
                      ricoprire l’incarico, dichiara:
                    </h4>
                  </div>

                  {values.altreDichiarazioni1 ? (
                    <div className="col-md-12 mb-1">
                      <p>
                        {selectedComitato === "Covisoc"
                          ? getString("ostative6")
                          : getString("ostative1")}
                      </p>
                    </div>
                  ) : null}

                  {values.altreDichiarazioni2 ? (
                    <div className="col-md-12 mb-1">
                      <p>
                        {selectedComitato === "Covisoc"
                          ? getString("ostative7")
                          : getString("ostative2")}
                      </p>
                    </div>
                  ) : null}

                  <div className="col-md-12 mb-1">
                    <label className="text-muted">
                      {formType === "Safe" ? getString("condanne1") : getString("condanne")}
                    </label>
                    <p>
                      {fromKeyToLabel(selectLists.it.condanne, values.condanne)}
                    </p>
                  </div>

                  <div className="col-md-12 mb-1">
                    <label className="text-muted">{formType === "Safe" ? getString("misure1") : getString("misure")}</label>
                    <p>
                      {fromKeyToLabel(selectLists.it.misure, values.misure)}
                    </p>
                  </div>

                  <div className="col-md-12 mb-1">
                    <label className="text-muted">
                      {getString("condizioni")}
                    </label>
                    <p>
                      {fromKeyToLabel(
                        selectLists.it.condizioni,
                        values.condizioni
                      )}
                    </p>
                  </div>

                  <div className="col-md-12 mb-1">
                    <label className="text-muted">
                      {getString("rapportoPA")}
                    </label>
                    <p>
                      {fromKeyToLabel(selectLists.it.pa, values.rapportoPA)}
                    </p>
                  </div>

                  {values.altreDichiarazioni1 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni1")}
                      </label>
                      {selectedComitato === "Covisoc" ? (
                        <p>
                          &nbsp;Il sottoscritto, in caso di nomina, si obbliga a
                          comunicare senza indugio alla F.I.G.C., il
                          sopravvenire di situazione di incompatibilità e/o il
                          venir meno dei requisiti alla nomina, nonché il
                          sopravvenire di ogni situazione che rientrasse nelle
                          ipotesi di cui ai punti 3.b), 4.b), 5.b) e 6.b).
                        </p>
                      ) : (
                        <p>
                          &nbsp;Il sottoscritto, in caso di nomina, si obbliga a
                          comunicare senza indugio alla F.I.G.C., alla
                          Commissione Federale di Garanzia ed al Presidente
                          dell’Organo di giustizia del quale è componente, il
                          sopravvenire di situazione di incompatibilità e/o il
                          venir meno dei requisiti alla nomina nell’organo di
                          giustizia sportiva, nonché il sopravvenire di ogni
                          situazione che rientrasse nelle ipotesi di cui ai
                          punti 3.b), 4.b), 5.b) e 6.b).
                        </p>
                      )}
                    </div>
                  ) : null}
                  {values.altreDichiarazioni2 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni2")}
                      </label>
                      {selectedComitato === "Covisoc" ? (
                        <p>
                          {" "}
                          &nbsp;Lo scrivente si impegna sin d’ora alla
                          osservanza di ogni disposizione di legge, federale e
                          comunque dell’ordinamento sportivo, ed in particolare
                          alla osservanza dell’obbligo di riservatezza e di
                          quello di partecipazione alle attività della Covisoc,
                          nonché a non rilasciare dichiarazioni agli organi di
                          stampa e ad altri mezzi di comunicazione in ordine
                          alle attività del proprio ufficio.
                        </p>
                      ) : (
                        <p>
                          &nbsp;Lo scrivente si impegna sin d’ora alla
                          osservanza di ogni disposizione di legge, federale e
                          comunque dell’ordinamento sportivo, ed in particolare
                          alla osservanza dell’obbligo di riservatezza e di
                          quello di partecipazione alle attività di detti
                          organi, nonché a non rilasciare dichiarazioni agli
                          organi di stampa e ad altri mezzi di comunicazione in
                          ordine ai processi in corso o a quelli nei quali fosse
                          chiamato a pronunciarsi.
                        </p>
                      )}
                    </div>
                  ) : null}
                  {values.altreDichiarazioni6 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni6")}
                      </label>
                      <p>
                        &nbsp;Lo scrivente si impegna ad osservare il divieto di
                        avere rapporti di qualsiasi natura con le società
                        soggette alla vigilanza della Covisoc e ad osservare
                        tale divieto per l'intero anno successivo alla
                        cessazione dell'incarico.
                      </p>
                    </div>
                  ) : null}
                  {values.altreDichiarazioni3 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni3")}
                      </label>
                      <p>
                        &nbsp;Lo scrivente si impegna a presentare apposita
                        dichiarazione di accettazione della nomina.
                      </p>
                    </div>
                  ) : null}
                  {values.altreDichiarazioni4 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni4")}
                      </label>
                      <p>
                        &nbsp;Le dichiarazioni contenute nella presente sono
                        state rilasciate dal sottoscritto assumendosi ogni
                        responsabilità penale e civile in caso di mendace
                        dichiarazione
                      </p>
                    </div>
                  ) : null}
                  {values.altreDichiarazioni5 ? (
                    <div className="col-md-12 mb-1">
                      <label className="text-muted">
                        {getString("altreDichiarazioni5")}
                      </label>
                      <p>
                        &nbsp;Ai sensi e per gli effetti del Regolamento Europeo
                        n. 679/2016, il sottoscritto presta il proprio consenso
                        al trattamento dei dati personali forniti.
                      </p>
                    </div>
                  ) : null}

                  <div className="col-md-12 mb-1">
                    <label className="text-muted">
                      {getString("cvAttachment")}
                    </label>
                    <p>{values.cvAttachment && values.cvAttachment.name}</p>
                  </div>

                  <div className="col-md-12 mb-1">
                    <button
                      className="btn btn-outline-primary btn-block"
                      type="submit"
                    >
                      {getString("confirm")}
                    </button>
                  </div>

                  <div className="col-md-12 mb-1 pb-1">
                    <label
                      className="text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setStepNumber(1);
                      }}
                    >
                      <u>{getString("back")}</u>
                    </label>
                  </div>
                </div>
                <div
                  className="col-md-12 mb-1"
                  style={{ display: stepNumber === 3 ? "block" : "none" }}
                >
                  <div id="validating-container" className="text-center">
                    <img
                      className="mb-4"
                      src="loading.gif"
                      alt=""
                      width="72"
                      height="72"
                    />
                    <h1 className="h4 mb-1 font-weight-normal">
                      {getString("generating")}
                    </h1>
                  </div>
                </div>
                <div
                  className="col-md-12 mb-1"
                  style={{ display: stepNumber === 4 ? "block" : "none" }}
                >
                  <div id="validating-container" className="text-center">
                    <h1 className="h3 mb-1 font-weight-normal">
                      {getString("thanks")}
                    </h1>
                    <h1 className="h4 mb-1 font-weight-normal">
                      {getString("tickets_sent")}
                    </h1>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      {/* <footer className="my-3 pt-1 text-muted text-center text-small">
        <label className="mb-1">Powered by Penguinpass. The smartest access system for your events.</label>
      </footer> */}
    </div>
  );
}

export default App;
